<template>
  <g id="g447" clip-path="url(#clipPath451)"
    ><path
      d="m 2163,7733.5 c 0,135.5859 -109.9141,245.5 -245.5,245.5 -135.5859,0 -245.5,-109.9141 -245.5,-245.5 0,-135.5859 109.9141,-245.5 245.5,-245.5 135.5859,0 245.5,109.9141 245.5,245.5"
      style="fill:none;stroke:#000000;stroke-width:24;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
      id="path453"/><path
      d="m 9194,6297.5 c 0,135.5859 -109.9141,245.5 -245.5,245.5 -135.5859,0 -245.5,-109.9141 -245.5,-245.5 0,-135.5859 109.9141,-245.5 245.5,-245.5 135.5859,0 245.5,109.9141 245.5,245.5"
      style="fill:none;stroke:#000000;stroke-width:24;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
      id="path455"/><path
      d="m 2203,5729.5 c 0,157.6772 -127.8227,285.5 -285.5,285.5 -157.6772,0 -285.5,-127.8228 -285.5,-285.5 0,-157.6773 127.8228,-285.5 285.5,-285.5 157.6773,0 285.5,127.8227 285.5,285.5"
      style="fill:none;stroke:#000000;stroke-width:24;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
      id="path457"/><path
      d="m 2182,5729.5 c 0,146.0791 -118.4206,264.5 -264.5,264.5 -146.0793,0 -264.5,-118.4209 -264.5,-264.5 0,-146.0791 118.4207,-264.5 264.5,-264.5 146.0794,0 264.5,118.4209 264.5,264.5"
      style="fill:none;stroke:#000000;stroke-width:24;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
      id="path459"/><path
      d="m 9234,8301.5 c 0,157.6777 -127.8223,285.5 -285.5,285.5 -157.6777,0 -285.5,-127.8223 -285.5,-285.5 0,-157.6773 127.8223,-285.5 285.5,-285.5 157.6777,0 285.5,127.8227 285.5,285.5"
      style="fill:none;stroke:#000000;stroke-width:24;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
      id="path461"/><path
      d="m 9213,8301.5 c 0,146.0791 -118.4209,264.5 -264.5,264.5 -146.0791,0 -264.5,-118.4209 -264.5,-264.5 0,-146.0791 118.4209,-264.5 264.5,-264.5 146.0791,0 264.5,118.4209 264.5,264.5"
      style="fill:none;stroke:#000000;stroke-width:24;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
      id="path463"/><path
      d="m 1993,7015.5 c 0,41.6973 -33.8025,75.5 -75.5,75.5 -41.6975,0 -75.5,-33.8027 -75.5,-75.5 0,-41.6973 33.8025,-75.5 75.5,-75.5 41.6975,0 75.5,33.8027 75.5,75.5"
      style="fill:none;stroke:#000000;stroke-width:24;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
      id="path465"/><path
      d="m 9024,7015.5 c 0,41.6973 -33.8027,75.5 -75.5,75.5 -41.6973,0 -75.5,-33.8027 -75.5,-75.5 0,-41.6973 33.8027,-75.5 75.5,-75.5 41.6973,0 75.5,33.8027 75.5,75.5"
      style="fill:none;stroke:#000000;stroke-width:24;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
      id="path467"/><path
      d="m 2182,6637.5 c 0,146.0791 -118.4206,264.5 -264.5,264.5 -146.0793,0 -264.5,-118.4209 -264.5,-264.5 0,-146.0791 118.4207,-264.5 264.5,-264.5 146.0794,0 264.5,118.4209 264.5,264.5"
      style="fill:none;stroke:#000000;stroke-width:24;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
      id="path469"/><path
      d="m 1861,6637.5 c 0,-31.2041 25.2959,-56.5 56.5,-56.5 31.2041,0 56.5,25.2959 56.5,56.5 0,31.2041 -25.2959,56.5 -56.5,56.5 -31.2041,0 -56.5,-25.2959 -56.5,-56.5"
      style="fill:none;stroke:#000000;stroke-width:24;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
      id="path471"/><path
      d="m 2031,8301.5 c 0,62.6846 -50.8157,113.5 -113.5,113.5 -62.6843,0 -113.5,-50.8154 -113.5,-113.5 0,-62.6846 50.8157,-113.5 113.5,-113.5 62.6843,0 113.5,50.8154 113.5,113.5"
      style="fill:none;stroke:#000000;stroke-width:24;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
      id="path473"/><path
      d="m 9062,5729.5 c 0,62.6841 -50.8154,113.5 -113.5,113.5 -62.6846,0 -113.5,-50.8159 -113.5,-113.5 0,-62.6841 50.8154,-113.5 113.5,-113.5 62.6846,0 113.5,50.8159 113.5,113.5"
      style="fill:none;stroke:#000000;stroke-width:24;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
      id="path475"/><path
      d="M 9213,9019 H 1652"
      style="fill:none;stroke:#000000;stroke-width:24;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
      id="path477"/><path
      d="m 9327,8905.5 c 0,62.6846 -50.8154,113.5 -113.5,113.5"
      style="fill:none;stroke:#000000;stroke-width:24;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
      id="path479"/><path
      d="M 9326,5125 V 8905"
      style="fill:none;stroke:#000000;stroke-width:24;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
      id="path481"/><path
      d="m 9213.5,5012 c 62.6846,0 113.5,50.8159 113.5,113.5"
      style="fill:none;stroke:#000000;stroke-width:24;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
      id="path483"/><path
      d="M 1652,5011 H 9213"
      style="fill:none;stroke:#000000;stroke-width:24;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
      id="path485"/><path
      d="m 1539,5125.5 c 0,-62.6841 50.8157,-113.5 113.5,-113.5"
      style="fill:none;stroke:#000000;stroke-width:24;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
      id="path487"/><path
      d="M 1539,8905 V 5125"
      style="fill:none;stroke:#000000;stroke-width:24;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
      id="path489"/><path
      d="m 1652.5,9019 c -62.6843,0 -113.5,-50.8154 -113.5,-113.5"
      style="fill:none;stroke:#000000;stroke-width:24;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
      id="path491"
  /></g>
</template>
<script>
export default {
  name: "Ma260Top",
};
</script>
